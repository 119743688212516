import { Button, Card, Center, Grid, Image, Text, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { createClient } from "contentful";
import { BananaBanner } from "../components";
import { getFunctions, httpsCallable } from "firebase/functions";
import BlogSkeletons from "../components/Skeletons/BlogSkeletons";
import { Pagination } from "@mantine/core";
import { usePagination } from "@mantine/hooks";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const Blog = (props) => {
  useAbleCDPTracking();

  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setPage] = useState(1);
  const location = useLocation();

  // Pagination logic
  const POSTS_PER_PAGE = 8;
  const totalPages = Math.ceil(blogPosts.items?.length / POSTS_PER_PAGE);
  const pagination = usePagination({ total: totalPages});

  const start = (pagination.active - 1) * POSTS_PER_PAGE;
  const end = start + POSTS_PER_PAGE;
  const currentPosts = blogPosts.items?.slice(start, end);

  useEffect(() => {
    setIsLoading(true);
    // Contentful client instance

    // console.log("Space ID:", process.env.REACT_APP_CONTENTFUL_SPACE_ID);
    // console.log("Access Token:", process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN);

    const contentfulClient = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      environment: "master", // defaults to 'master' if not set
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });

    contentfulClient
      .getEntries({
        content_type: "blogPage",
      })
      .then((response) => {
        // console.log("Raw fetch response from Contentful:", response);
        setBlogPosts(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching entries:", error);
        setIsLoading(false);
      });

    // if (location.state && location.state.currentPage != null) {
    //   pagination.setPage(location.state.currentPage);
    // }
  }, []);

  return (
    <div id="wrapper" className="h-fit m-auto flex-grow flex-col w-full relative justify-center ">
      <BananaBanner title1="Bid Banana" title2="Blog" />
      <div className="pb-15">
        <br />
        <br />
        <Center>
          <div className="flex justify-center xl-container m-auto" style={{ maxWidth: "2000px" }}>
            {isLoading ? (
              <BlogSkeletons />
            ) : (
              <Grid
                gutter="xs" // Default gutter size for smaller screens
                gutterLg="md" // Medium gutter size for large screens
                gutterXl="lg" // Large gutter size for extra-large screens
                grow
                spacing="md"
                className="flex justify-center p-0 mb-5 sm:mb-15 mt-5 sm:mt-10"
              >
                {currentPosts?.map((post, index) => (
                  <Grid.Col
                    key={index}
                    span="content"
                    lg={3} // On large screens, 4 columns (3 * 4 = 12)
                    md={6} // 2 columns on medium screens (md)
                    sm={12} // 1 column on small screens (sm)
                  >
                    <div key={post.fields.urlSlug} className="flex justify-center">
                      <div className="sm:max-w-xl sm:mx-4 mx-2 max-w-[300px]">
                        <Link to={`/articles/${post.fields.urlSlug}`} state={{currentPage: pagination.active}} id="Link">
                          <Card p={0} className="bg-white relative m-2 mb-8 mx-4 max-w-sm hover:-translate-y-1 transition-all ease-in-out duration-300 hover:bg-white duration-900">
                            <Card.Section>
                              <div id="post">
                                <div id="post-header">
                                  <img src={post.fields.image.fields.file.url} alt={post.fields.title} title="" height="200" className="w-full object-cover mb-4 rounded-sm" loading="lazy" />
                                </div>
                              </div>
                            </Card.Section>
                            <Text id="blog-title" className="block mb-4 text-xl font-bold hover:underline hover:bold hover:text-bb-dark-blue" ta="left">
                              {post.fields.title}
                            </Text>
                            <Text id="post-description" className="text-md text-gray-500 line-clamp-4" color="dimmed" lineClamp={4}>
                              <p>{post.fields.blogSummary}</p>
                            </Text>
                          </Card>
                        </Link>
                      </div>
                    </div>
                  </Grid.Col>
                ))}
              </Grid>
            )}
          </div>
        </Center>
        <div className="flex justify-center pt-5">
          <Pagination
            page={pagination.active}
            onChange={pagination.setPage}
            total={totalPages}
            className="to-bb-lightyellow"
            styles={(theme) => ({
              item: {
                "&[data-active]": {
                  backgroundColor: "#fde299",
                  color: "#151515",
                },
              },
            })}
          />
        </div>
      </div>

      <div id="footer">
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default Blog;
