import {
  createStyles,
  Image,
  Container,
  Title,
  Text,
  Button,
  SimpleGrid,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import BananaMan from "../data/BananaMan.svg";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const useStyles = createStyles((theme) => ({
  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

const NotFound = () => {
  useAbleCDPTracking()

  const { classes } = useStyles();

  const navigate = useNavigate();

  const goHome = () => {

    navigate("/");
  };

  return (
    <div
      id="wrapper"
      className="h-fit flex-grow w-full relative justify-center"
    >
      <Container className="mt-20">
        <SimpleGrid
          spacing={80}
          cols={2}
          breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}
        >
          <Image src={BananaMan} className={classes.mobileImage} />
          <div>
            <Title className="font-semibold font-Barlow mb-4 sm:mt-12 ">
              Something is not right...
            </Title>
            <Text color="dimmed" size="lg">
              The page you are trying to open does not exist. You may have
              mistyped the address, or the page has been moved to another URL.
              If you think this is an error contact support.
            </Text>
            <div className="flex justify-center sm:block">
              <Button
                onClick={goHome}
                variant="outline"
                size="lg"
                type="submit"
                radius="xl"
                mt="xl"
                className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              >
                Back to home
              </Button>
            </div>
          </div>
          <Image src={BananaMan} className={classes.desktopImage} />
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default NotFound;
