import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Button, Anchor, Spoiler, Tooltip, Modal, Stepper, Menu, ActionIcon, Divider } from "@mantine/core";
import { BananaBanner, GlobalLoader, SimilarBidCard, KickoffForm, BidDataErrorForm, Scheduler, AiIcon, BidLabSalesComponent, BidNotFound, BidPageLimitReached, AccessLimitModal } from "../components";
import { useParams } from "react-router-dom";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";
import { db } from "../Firebase";
import { doc, getDoc } from "firebase/firestore";
import { IoChevronBackSharp, IoChevronBack } from "react-icons/io5";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { Helmet } from "react-helmet-async";
import { HiQuestionMarkCircle, HiOutlineDotsVertical, HiOutlineLockClosed } from "react-icons/hi";
import { TiCreditCard } from "react-icons/ti";
import { TbCalendarTime } from "react-icons/tb";
import { functions } from "../Firebase";
import { Carousel } from "@mantine/carousel";
import { IconArrowRight, IconArrowLeft } from "@tabler/icons-react";
import { useUserAuth } from "../contexts/UserAuthContext";
import { IconCheck, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { httpsCallable } from "firebase/functions";
import BananaOn from "../components/Buttons/Bananas/BananaOn";
import BananaOff from "../components/Buttons/Bananas/BananaOff";

const BidPage = () => {
  useAbleCDPTracking();

  // Fetch bidId from the URL
  const { bidId } = useParams();

  const [bid, setBid] = useState(null);
  const [accessState, setAccessState] = useState(true);
  const [files, setFiles] = useState(null);
  const [hasContacts, setHasContacts] = useState(null);
  const [similarBids, setSimilarBids] = useState(null);
  const [exists, setExists] = useState(true);

  // State to manage Modal popup
  const [modalOpened, setModalOpened] = useState(false);

  // State to manage data error Modal popup
  const [dataErrorModalOpened, setDataErrorModalOpened] = useState(false);

  // State to manage the two steps of the get started form
  const [step, setStep] = useState(1);
  const [active, setActive] = useState(1);

  const navigate = useNavigate();
  const location = useLocation();
  const AuthContext = useUserAuth();
  const checkAccessFunction = httpsCallable(functions, "bidPageAccessCheck");

  // is close date classified as TBD
  const isTBDCloseDate = bid?.close_date.includes("2099");

  // Load bid data
  useEffect(() => {
    const bid_doc = doc(db, "bids", bidId);
    getDoc(bid_doc).then((doc) => {
      if (doc.exists()) {
        window.scrollTo(0, 0);
        const data = doc.data();
        if (data.contact_email || data.contact_phone || data.contact_name) {
          setHasContacts(true);
        }
        setBid(doc.data());
      } else {
        setExists(false);
      }
    });
  }, [bidId]);

  // Access state check
  useEffect(() => {
    if (!AuthContext.authLoading) {
      if (!AuthContext.user || AuthContext.user.subscriptionStatus != "active") {
        if (bid === null) {
          try {
            // Call the function to ask for access
            const res = checkAccessFunction().then((result) => {
              console.log(result.data);
              setAccessState(result.data.access);
            });
          } catch (error) {
            console.error("Error:", error);
            setAccessState(false);
          }
        }
      } else {
        setAccessState(true);
      }
    }
  }, [AuthContext.authLoading, bid]);

  useEffect(() => {
    if (!bid) return;
    let fileRecords;
    if (bid.files)
      try {
        const { error, file_records } = JSON.parse(bid.files);
        if (Array.isArray(file_records)) {
          fileRecords = file_records;
        } else throw new Error(error || `No stated "error" from backend, yet "file_records" is not an Array.`);
      } catch (ex) {
        console.log("Error parsing files:", ex);
      }
    setFiles(fileRecords || []);
  }, [bid]);

  // Fetch similar bids.
  useEffect(() => {
    if (similarBids === null) {
      if (bid != null) {
        fetchSimilarBids().then((response) => {
          if (Array.isArray(response?.result)) {
            setSimilarBids(response.result);
          } else {
            setSimilarBids([]);
          }
        });
      }
    }
  }, [bid, similarBids]);

  const url = "https://tbl-apis.rocknerve.org/GenFeed/rfp-to-feed";

  const headers = {
    "API-Key": "hNa2bMrkbNlGyCHFhrSd",
    "Content-Type": "application/json",
  };

  const data = JSON.stringify({
    top_n: 10,
    doc_id: bidId,
  });

  async function fetchSimilarBids() {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: data,
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  }

  const similarBidNavigate = (e, bid_id) => {
    e.preventDefault();
    setBid(null);
    setSimilarBids(null);
    setAccessState(null);
    navigate(`/bid/${bid_id}`, { state: { hasHistory: true }, replace: true });
  };

  const goBackOnClick = (e) => {
    e.preventDefault();
    if (location.state?.hasHistory) {
      // If location contains Bid Banana history use it, otherwise use default
      navigate(-1);
    } else {
      navigate("/results");
    }
  };

  const kickoffBidOnClick = () => {
    setModalOpened(true);
  };

  const bidDataErrorOnclick = () => {
    setDataErrorModalOpened(true);
  };

  // function for setting next state
  function handleNextStep() {
    setStep(step + 1);
    setActive((current) => (current < 2 ? current + 1 : current));
  }

  // function for setting th previous state
  function handlePreviousStep() {
    setStep(step - 1);
    setActive((current) => (current > 0 ? current - 1 : current));
  }

  // This calls the asynchronous modifyUserFavorite function
  const favoritesOnClick = (title) => {
    modifyUserFavorite(title);
  };

  // This function either appends or deletes the item from the users favorites
  async function modifyUserFavorite() {
    const user = AuthContext.user;
    if (user) {
      // Create a deep, mutable copy of the favorites state and use it to modify the user favorites
      let favoritesCopy = structuredClone(AuthContext.favorites);

      if (bidId in favoritesCopy) {
        delete favoritesCopy[bidId];
        showNotification({
          title: "Favorite removed",
          icon: <IconX size={18} />,
          autoClose: 3000,
          message: `Removed "${bid.title}" from favorites!`,
          color: "red",
        });
      } else {
        favoritesCopy[bidId] = bid;
        showNotification({
          title: "Favorite added",
          icon: <IconCheck size={18} />,
          autoClose: 3000,
          message: `Added "${bid.title}" to favorites!`,
          color: "green",
        });
      }
      AuthContext.setUpdateFavorites(favoritesCopy);
    }
  }

  const handleFileDownload = async (e, file) => {
    e.preventDefault();
    try {
      const gcs_url = `https://storage.googleapis.com/bb_rfp_doc_files/${file.file_stored_as}`;
      const response = await fetch(gcs_url, { mode: "cors" });
      const blob_temp_url = URL.createObjectURL(await response.blob());
      const a_elem = document.createElement("a");
      a_elem.href = blob_temp_url;
      a_elem.download = file.file_name;
      a_elem.click();
      URL.revokeObjectURL(blob_temp_url);
    } catch (error) {
      console.error("Error downloading RFP file:", error);
    }
  };

  if (!exists) {
    return <BidNotFound />;
  }

  if (!bid) {
    return <GlobalLoader />;
  }

  let favoritesBanana;
  let isFavorited = false;
  if (AuthContext.favorites != null && Object.keys(AuthContext.favorites).length != 0) {
    isFavorited = bidId in AuthContext.favorites;
    favoritesBanana = isFavorited ? <BananaOn /> : <BananaOff />;
  } else {
    favoritesBanana = <BananaOff />;
  }

  return (
    <div id="wrapper" className="h-fit m-auto flex-grow flex-col w-full relative justify-center ">
      {/* SEO */}
      <Helmet prioritizeSeoTags>
        <title>{bid?.title}</title>
        {!bid.description.startsWith(`Click the 'Link to bid' button`) && <meta name="description" content={bid.description.substring(0, 300)} />}
      </Helmet>
      <BananaBanner title1={bid.title} />

      <section className="bg-white">
        <div className="flex sm:w-64 pt-2">
          <nav className="sm:ml-auto inline-block">
            <Link onClick={(e) => goBackOnClick(e)} id="Link" className="flex items-center text-cyan-700 font-semibold group">
              <div className="mr-1 transition-transform ease-in-out duration-300 group-hover:-translate-x-1">
                <IoChevronBackSharp className="self-center" />
              </div>
              <span className="group-hover:underline">Back</span>
            </Link>
          </nav>
        </div>
        <div className="flex-row max-w-screen-xl mt-2 py-2 mx-auto lg:gap-8 xl:gap-0 lg:py-4 lg:col-span-2 border-2 rounded-xl">
          {/* {bid.description_mini &&
            <p className="m-auto justify-center text-center text-base text-gray-600 my-2 italic" style={{ padding: "0 300px 10px 300px" }}>
              {bid.description_mini}
            </p>
          } */}

          <div className="flex">
            <div className="mr-auto lg:col-span-2">
              <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                Title:&nbsp;&nbsp;
                <p className="font-normal sm:max-w-3xl">{bid.title}</p>
              </span>

              <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                Agency:&nbsp;&nbsp;
                <p className="font-normal sm:max-w-3xl">{bid.agency}</p>
              </span>

              <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                State:&nbsp;&nbsp;
                <p className="font-normal sm:max-w-3xl">{bid.state_displayable}</p>
              </span>

              {bid.unspsc_code != null && bid.unspsc_code != 0 && (
                <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                  UNSPSC Code:&nbsp;&nbsp;
                  <p className="font-normal sm:max-w-3xl">{bid.unspsc_code}</p>
                </span>
              )}

              {bid.naics_code != null && bid.naics_code != 0 && (
                <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                  NAICS Code:&nbsp;&nbsp;
                  <p className="font-normal sm:max-w-3xl">{bid.naics_code}</p>
                </span>
              )}

              {bid.industry && (
                <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                  Industry:&nbsp;&nbsp;
                  <p className="font-normal sm:max-w-3xl">{bid.industry}</p>
                </span>
              )}

              {bid.opportunity_type && (
                <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                  Solicitation Type:&nbsp;&nbsp;
                  <p className="font-normal sm:max-w-3xl">{bid.opportunity_type}</p>
                </span>
              )}

              {bid.agency_rfp_id && (
                <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                  Solicitation ID:&nbsp;&nbsp;
                  <p className="font-normal sm:max-w-3xl">{bid.agency_rfp_id}</p>
                  <div>
                    <Tooltip
                      label="The source specific ID for this opportunity."
                      allowPointerEvents
                      withArrow
                      multiline
                      width={240}
                      position="right"
                      openDelay={100}
                      transition="pop"
                      transitionDuration={200}
                      color="bb-blue"
                    >
                      <p className="ml-2 mt-1">
                        <HiQuestionMarkCircle size={23} className="text-gray-400" />
                      </p>
                    </Tooltip>
                  </div>
                </span>
              )}
            </div>

            <div className="mr-auto px-2 lg:col-span-2">
              <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                Open Date:&nbsp;&nbsp;
                <p className="font-normal sm:max-w-3xl">{bid.open_date}</p>
              </span>

              {bid.meeting_date && (
                <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                  Pre-Bid Meeting Date:&nbsp;&nbsp;
                  <p className="font-normal sm:max-w-3xl">
                    {bid.meeting_date}
                    {bid.meeting_is_mandatory ? <span className="ml-2 text-base">(Mandatory)</span> : null}
                  </p>
                </span>
              )}

              {bid.visit_date && (
                <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                  Site Visit Date:&nbsp;&nbsp;
                  <p className="font-normal sm:max-w-3xl">
                    {bid.visit_date}
                    {bid.visit_is_mandatory ? <span className="ml-2 text-base">(Mandatory)</span> : null}
                  </p>
                </span>
              )}

              {bid.question_date && (
                <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                  Questions Due Date:&nbsp;&nbsp;
                  <p className="font-normal sm:max-w-3xl">{bid.question_date}</p>
                </span>
              )}

              <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                Close Date:&nbsp;&nbsp;
                <p className="font-normal sm:max-w-3xl">{isTBDCloseDate ? "TBD " : bid.close_date}</p>
                <div>
                  {isTBDCloseDate && (
                    <Tooltip
                      label="The close date is either continuous or unknown at this time."
                      allowPointerEvents
                      withArrow
                      multiline
                      width={240}
                      position="right"
                      openDelay={100}
                      transition="pop"
                      transitionDuration={200}
                      color="bb-blue"
                    >
                      <p className="ml-2 mt-1">
                        <HiQuestionMarkCircle size={23} className="text-gray-400" />
                      </p>
                    </Tooltip>
                  )}
                </div>
              </span>

              {bid.award_date && (
                <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                  Projected Award Date:&nbsp;&nbsp;
                  <p className="font-normal sm:max-w-3xl">{bid.award_date}</p>
                </span>
              )}

              {bid.project_duration && (
                <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                  Project Duration:&nbsp;&nbsp;
                  <p className="font-normal sm:max-w-3xl">{bid.project_duration}</p>
                </span>
              )}

              <span className="flex p-2 ml-3 sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
                Last Updated:&nbsp;&nbsp;
                <p className="font-normal sm:max-w-3xl">{new Date(bid.updation_timestamp * 1000).toLocaleDateString()}</p>
              </span>
            </div>
            <div className="top-0 sm:flex sm:flex-col tour-favorite">
              <div className="flex flex-row justify-end">
                <div className="inline-flex pr-3">
                  <Tooltip
                    label={`${isFavorited ? "Remove from favorites" : "Add to favorites"}`}
                    withArrow
                    position="top"
                    offset={5}
                    openDelay={600}
                    transition="pop"
                    transitionDuration={200}
                    color="bb-blue"
                  >
                    <ActionIcon
                      variant="transparent"
                      size="xl"
                      color="gray.4"
                      radius="lg"
                      id="FavoritesButton"
                      onClick={function (e) {
                        e.preventDefault();
                        favoritesOnClick(bid.title);
                      }}
                      className="mt-2"
                    >
                      {favoritesBanana}
                    </ActionIcon>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <Divider className="flex items-center m-auto w-5/6 justify-center my-8" />

          <section className="bg-white">
            <div className="flex max-w-screen-xl px-4 py-4 my-4 mx-auto lg:gap-8 xl:gap-0 lg:py-8 lg:col-span-2">
              <span className="break-words">
                <span className="text-xl font-semibold">Description:</span>
                <span className="flex text-justify my-2">
                  <Spoiler
                    maxHeight={305}
                    showLabel="Show more"
                    hideLabel="Hide"
                    className={`self-stretch flex-column sm:text-xl ${bid.description_use_newlines ? "whitespace-pre-line leading-none" : "whitespace-normal"} font-light justify-center`}
                  >
                    {bid.description}
                  </Spoiler>
                </span>
              </span>
            </div>
          </section>

          <Divider className="flex items-center m-auto w-5/6 justify-center my-8" />

          {files && files.length > 0 && !bid.files_should_hide ? (
            <section className="bg-white">
              <div className="flex-col max-w-screen-xl px-4 py-4 my-4 mx-auto lg:gap-8 xl:gap-0 lg:py-8 lg:col-span-2">
                <span className="text-xl font-semibold">Attached Files: </span>
                <span className="flex-col text-justify my-2">
                  <div className="pt-4">
                    {files.map((file) => {
                      return (
                        <p className="flex text-xl">
                          {/* inner <span> to avoid the whole horizontal row being clickable! */}
                          <span
                            className="text-blue-500 underline hover:text-blue-700 focus:outline-none focus:ring focus:ring-blue-300 transition duration-200 cursor-pointer"
                            onClick={(e) => handleFileDownload(e, file)}
                          >
                            {file.file_title || file.file_name}
                          </span>
                        </p>
                      );
                    })}
                  </div>
                </span>
              </div>
            </section>
          ) : (
            <section className="bg-white">
              <div className="flex-col max-w-screen-xl px-4 py-4 my-4 mx-auto lg:gap-8 xl:gap-0 lg:py-6 lg:col-span-2">
                <span className="text-xl font-semibold">Attached Files: </span>
                <span className="flex-col text-justify my-2">
                  <p className="flex m-auto text-xl text-gray-600 my-2">Please visit the bid source via the “Link to Bid Source” button below for documentation.</p>
                </span>
              </div>
            </section>
          )}

          <Divider className="flex items-center m-auto w-5/6 justify-center my-8" />

          {hasContacts ? (
            <section className="bg-white">
              <div className="flex-col max-w-screen-xl px-4 py-4 my-4 mx-auto lg:gap-8 xl:gap-0 lg:py-6 lg:col-span-2">
                <span className="text-xl font-semibold">Contact Information: </span>
                <span className="flex-col text-justify my-2">
                  <div className="pt-4">
                    <p className="flex text-xl font-light">
                      {bid.contact_name}
                      {bid.contact_role ? <span className="ml-2 text-sm pt-1">({bid.contact_role})</span> : null}
                    </p>
                    <p className="flex text-xl font-light">{bid.contact_email}</p>
                    <p className="flex text-xl font-light">{bid.contact_phone}</p>
                  </div>
                </span>
              </div>
            </section>
          ) : (
            <section className="bg-white">
              <div className="flex-col max-w-screen-xl px-4 py-4 my-4 mx-auto lg:gap-8 xl:gap-0 lg:py-6 lg:col-span-2">
                <span className="text-xl font-semibold">Contact Information: </span>
                <span className="flex-col text-justify my-2">
                  <p className="flex m-auto text-xl text-gray-600 my-2">There is no contact information available at this time.</p>
                </span>
              </div>
            </section>
          )}

          <Divider className="flex items-center m-auto w-5/6 justify-center my-8" />

          {bid.budget ? (
            <section className="bg-white">
              <div className="flex-col max-w-screen-xl px-4 py-4 my-4 mx-auto lg:gap-8 xl:gap-0 lg:py-6 lg:col-span-2">
                <span className="text-xl font-semibold">Budget Estimate{bid.budget_usedai ? " (AI)" : ""}:</span>
                <span className="flex-col text-justify my-2">
                  <p className="py-4 self-stretch flex-column sm:text-xl font-light justify-center">{bid.budget}</p>
                  <p className="self-stretch flex-column sm:text-base font-light justify-center italic">{bid.budget_rationale}</p>
                </span>
              </div>
            </section>
          ) : (
            <section className="bg-white">
              <div className="flex-col max-w-screen-xl px-4 py-4 my-4 mx-auto lg:gap-8 xl:gap-0 lg:py-6 lg:col-span-2">
                <span className="text-xl font-semibold">Budget Estimate: </span>
                <span className="flex-col text-justify my-2">
                  <p className="flex m-auto text-xl text-gray-600 my-2">There is no budget estimate available at this time.</p>
                </span>
              </div>
            </section>
          )}

          <div className="flex pt-4 mx-4">
            <div className="flex items-center">
              <span className="flex">
                <div className={bid.ai_enhanced ? "visible" : "hidden"}>
                  <Tooltip
                    label="Some data for this bid has been extracted using AI"
                    withArrow
                    multiline
                    position="bottom"
                    width={185}
                    openDelay={400}
                    transition="pop"
                    transitionDuration={200}
                    color="bb-blue"
                    zIndex={99}
                  >
                    <p className="mr-4">
                      <AiIcon size={24} className="text-gray-400" />
                    </p>
                  </Tooltip>
                </div>
                <div className={bid.login_required ? "visible" : "hidden"}>
                  <Tooltip label="This bid requires an additional login" withArrow multiline position="bottom" width={240} openDelay={300} transition="pop" transitionDuration={200} color="bb-blue">
                    <p className="mr-4">
                      <HiOutlineLockClosed size={24} className="text-gray-400" />
                    </p>
                  </Tooltip>
                </div>
                <div className={bid.payment_required ? "visible" : "hidden"}>
                  <Tooltip label="This bid requires additional payment" withArrow multiline position="bottom" width={240} openDelay={300} transition="pop" transitionDuration={200} color="bb-blue">
                    <p className="mr-4">
                      <TiCreditCard size={26} stroke={1.5} className="text-gray-400" />
                    </p>
                  </Tooltip>
                </div>
                <div className={new Date(bid.open_date) > new Date() ? "visible" : "hidden"}>
                  <Tooltip
                    label="This is a forecasted opportunity and the details of this bid may change over time."
                    withArrow
                    multiline
                    position="bottom"
                    width={270}
                    openDelay={300}
                    transition="pop"
                    transitionDuration={200}
                    color="bb-blue"
                  >
                    <p className="">
                      <TbCalendarTime size={24} className="text-gray-400" />
                    </p>
                  </Tooltip>
                </div>
              </span>
            </div>
            <div className="flex ml-auto items-center sm:text-xl font-semibold flex-wrap sm:flex-nowrap">
              <Menu shadow="md" withinPortal>
                <Menu.Target>
                  <ActionIcon>
                    {" "}
                    <HiOutlineDotsVertical size={32} />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    icon={<BsFillExclamationCircleFill size={20} className="text-red-400" />}
                    onClick={() => {
                      bidDataErrorOnclick();
                    }}
                  >
                    Report Inaccurate Data
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>

              <Anchor href={bid.url} target="_blank" className="no-underline hover:no-underline">
                <Button
                  variant="default"
                  size="sm"
                  radius="xl"
                  className="hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal mr-2"
                >
                  Link to Bid Source
                </Button>
              </Anchor>
              <Button
                onClick={() => kickoffBidOnClick()}
                variant="outline"
                size="sm"
                radius="xl"
                className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-normal ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              >
                Get Started!
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-bb-lightyellow my-12">
        <div className="flex-col max-w-screen-xl px-4 py-4 my-4 mx-auto lg:gap-8 xl:gap-0 lg:py-8">
          <span className="flex my-8 text-3xl justify-center m-auto font-semibold mb-8 ">Similar Opportunities provided by Bid Banana AI{"\u2122"}</span>
          {similarBids != null && similarBids.length > 0 ? (
            <Carousel
              withIndicators
              slideSize="70%"
              align="center"
              controlsOffset="xs"
              breakpoints={[{ maxWidth: "sm", slideSize: "100%" }]}
              classNames={{ indicator: "bg-leaf-dark-green", control: "bg-leaf-lightest-green mt-[8rem]" }}
              controlSize={32}
              styles={{
                control: {
                  "&[data-inactive]": {
                    opacity: 0,
                    cursor: "default",
                  },
                },
                indicator: {
                  width: 12,
                  height: 12,
                  // transition: 'height 250ms ease, width 250ms ease',
                  alignContent: "center",
                  marginTop: "auto",
                  marginBottom: "auto",
                  "&[data-active]": {
                    width: 18,
                    height: 18,
                  },
                },
              }}
              nextControlIcon={<IconArrowRight size={20} />}
              previousControlIcon={<IconArrowLeft size={20} />}
            >
              {similarBids.map((similar_bid, index) => {
                return (
                  <Carousel.Slide key={index}>
                    <div className="flex justify-center w-3/4 m-auto" onClick={(e) => similarBidNavigate(e, similar_bid.doc_id)}>
                      <SimilarBidCard bid={similar_bid} />
                    </div>
                  </Carousel.Slide>
                );
              })}
            </Carousel>
          ) : (
            <h2 className="flex p-24 text-xl font-semibold m-auto justify-center">Failed to fetch similar bids, try again later or contact support if the problem persists.</h2>
          )}
        </div>
      </section>

      <section className="bg-white">
        <BidLabSalesComponent />
      </section>

      {/* access limit modal */}

      <Modal
        centered
        size="auto"
        opened={!accessState}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        overlayProps={{
          opacity: 0.55,
          blur: 3,
        }}
      >
        <AccessLimitModal />
      </Modal>

      {/* kickoff form */}

      <Modal centered size="xl" closeOnEscape opened={modalOpened} onClose={() => setModalOpened(false)} onSubmit={() => setModalOpened(true)}>
        <div className="m-2 p-2 ">
          {/* Progress stepper */}
          <Stepper
            active={active}
            onStepClick={setActive}
            breakpoint="sm"
            color="cyan"
            classNames={{
              root: "font-Lexend-Deca-Regular ",
              stepIcon: "font-Lexend-Deca-Regular",
              stepCompletedIcon: "font-Lexend-Deca-Regular ",
              stepLabel: "font-Lexend-Deca-Regular",
              stepDescription: "font-Lexend-Deca-Regular",
            }}
          >
            <Stepper.Step label="Step 1" description="Confirm selection" className="font-Lexend-Deca-Regular"></Stepper.Step>
            <Stepper.Step label="Step 2" description="Schedule meeting" className="font-Lexend-Deca-Regular"></Stepper.Step>
          </Stepper>
        </div>
        {/* Conditional rendering state for which component to show */}
        {step === 1 ? <KickoffForm handleClose={() => setModalOpened(false)} title={bid.title} url={bid.url} handleNextStep={handleNextStep} /> : <Scheduler handleClose={() => setStep(0)} />}
        {step === 1 ? (
          <div id="button container" className="flex justify-end"></div>
        ) : (
          <div id="button container" className="flex justify-start">
            <Button variant="default" className="border-1 border-[#425b76] text-[#425b76] font-Lexend-Deca-Regular" leftIcon={<IoChevronBack />} onClick={handlePreviousStep}>
              Back
            </Button>
          </div>
        )}
      </Modal>

      {/* Bid data error form */}

      <Modal centered size="xl" closeOnEscape opened={dataErrorModalOpened} onClose={() => setDataErrorModalOpened(false)} onSubmit={() => setDataErrorModalOpened(false)}>
        <BidDataErrorForm handleClose={() => setDataErrorModalOpened(false)} title={bid.title} url={bid.url} siteName={bid.site_name} />
      </Modal>
    </div>
  );
};

export default BidPage;
