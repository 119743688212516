import React from "react";
import { Group, Spoiler, Tooltip } from "@mantine/core";

const SimilarBidCard = (props) => {
  const bid = props.bid;
  
  // Helper function to calculate the background color based on relevance value
  const getRelevanceColor = (relevance) => {
    const red = Math.floor((1 - relevance) * 255); // More red at lower relevance
    const green = Math.floor(relevance * 255); // More green at higher relevance
    return `rgb(${red}, ${green}, 0)`;
  };

  const relevanceColor = getRelevanceColor(bid.RELEVANCE);

  return (
    <div className="flex-col m-auto w-full">
      <div className="justify-center m-auto w-full">
        <article className="break-words w-full whitespace-pre-line bg-white flex-nowrap mb-10 mx-3 border-1 border-gray-400 rounded-xl drop-shadow-sm hover:cursor-pointer hover:bg-bb-yellow hover:border-black transition-all ease-in-out duration-200">
          <Group position="apart" className="flex-nowrap items-start">
            <span className=" flex p-2 pt-4 ml-3 font-semibold flex-wrap sm:flex-nowrap w-5/6">
              Title:&nbsp;&nbsp;
              <p className="sm:max-w-3xl">{bid.title}</p>
            </span>
            <Tooltip
              label="This percentage represents how closely the recommended bid matches with the details of this bid."
              allowPointerEvents
              withArrow
              multiline
              width={240}
              position="bottom"
              openDelay={100}
              transition="pop"
              transitionDuration={200}
              color="bb-blue"
            >
              <div className="absolute top-2 right-2 text-white text-sm font-semibold" style={{ backgroundColor: relevanceColor, padding: "4px 8px", borderRadius: "4px" }}>
                {bid.RELEVANCE.toFixed(2) * 100}% Match
              </div>
            </Tooltip>
          </Group>
          <span className="flex p-2 ml-3 font-semibold flex-wrap">
            Agency:&nbsp;&nbsp;{" "}
            <span className="max-w-md sm:max-w-[33rem] pl-0 font-light">
              <p className=" sm:max-w-3xl">{bid.agency}</p>
            </span>
          </span>
          <span className="flex p-2 ml-3 font-semibold flex-wrap">
            State:&nbsp;&nbsp; <p className="pl-0 font-light">{bid.state_displayable}</p>
          </span>
          <span className="flex p-2 ml-3 font-semibold">
            Open Date: <p className="max-w-3xl pl-2 font-light">{bid.open_date}</p>
          </span>
          <span className="flex p-2 ml-3">
            <div className="flex font-semibold">
              Close Date: <p className="pl-2 font-light">{bid.close_date}</p>
            </div>
          </span>
        </article>
      </div>
    </div>
  );
};

export default SimilarBidCard;
