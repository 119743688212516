import React from "react";
import { Link } from "react-router-dom";
import { Button, List, ThemeIcon } from "@mantine/core";
import Bidlabgroup from "../data/Bidlabgroup.svg";
import { IconCircleCheck } from "@tabler/icons";

const AccessLimitModal = () => {
  return (
    <div className="m-x-md m-3">
      <section className="bg-white">
        <div className="grid max-w-screen-lg px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center px-2 lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">You have reached your limit of free bid views!</h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
              Bid Banana was built by a team of RFP specialists and skilled developers. Together they created the industry's best RFP search engine. Bid Banana helps you easily find RFPs across all
              lines of business and geographic locations. Conducting RFP searches has never been simpler or more, dare we say, bananas.
            </p>
            <h2 className="max-w-2xl mb-6 text-xl font-semibold leading-none">Sign up today for a 7 day free trial including:</h2>
            <List
              type="unordered"
              size="lg"
              spacing="sm"
              className="text-gray-500 pb-5 "
              icon={
                <ThemeIcon color="#98D7A2" size={24} radius="xl">
                  <IconCircleCheck size={16} />
                </ThemeIcon>
              }
            >
              <List.Item>Unlimited access to over 35,000+ local, state, and federal RFPs</List.Item>
              <List.Item>Filter + save your searches to fit your company's needs</List.Item>
              <List.Item>Consultants on-hand to help if needed</List.Item>
            </List>
            <div className="flex justify-center sm:block my-4">
              <Button
                variant="outline"
                as="a"
                size="md"
                radius="xl"
                target="_blank"
                className="flex-col mr-4 w-fit bg-bb-yellow hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow border-black duration-900 text-gray-600 font-normal"
              >
                <Link to="/register" className="text-gray-800 font-normal hover:text-gray-800 no-underline hover:no-underline">
                  Sign Up
                </Link>
              </Button>
              <Button
                variant="outline"
                as="a"
                size="md"
                radius="xl"
                target="_blank"
                className="flex-col w-fit bg-leaf-medium-green hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-leaf-medium-green border-black duration-900 text-gray-600 font-normal"
              >
                <Link to="/login" className="text-gray-800 font-normal hover:text-gray-800 no-underline hover:no-underline">
                  Log In
                </Link>
              </Button>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={Bidlabgroup} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AccessLimitModal;
