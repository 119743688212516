import React, {useState} from 'react'
import { SimpleGrid, Card, Modal, Button, Image } from '@mantine/core'
import { BananaBanner, UploadBidForm, UploadBidSourceForm } from '../components'
import Banana from '../data/Bid Banana UNPEELED Banana Full Color.png'
import Bunch from '../data/Bid Banana BUNCH Banana Full Color.png'
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const Upload = () => {
  useAbleCDPTracking()
// State for upload a bid to bid banana Modal
const [opened1, setOpened1] = useState(false);
const [opened2, setOpened2] = useState(false);

const uploadBidOnClick = () => {
    setOpened1(true);
  }

const uploadBidSourceOnClick = () => {
  setOpened2(true);
}


  return (
    <div id='wrapper' className='h-fit m-auto flex-grow flex-col w-full relative justify-center '>
      <BananaBanner title1='Upload' title2='a bid or bid source' />    
      <section className='my-10 max-w-7xl m-auto relative pl-5 pr-5'>
      <SimpleGrid 
        cols={2} 
        spacing='xl'
        mt={50}
        breakpoints={[
          { maxWidth: 'md', cols: 1 }
        ]}
        >
         
          {/* Card 1: Upload a bid */}
          <Card
            shadow="sm"
            p="xl"
            component="a"
            withBorder
            radius='lg'
            className='hover:drop-shadow-lg transition-all ease-in-out duration-300'
            // href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
          
            >
            <Card.Section>
              <Image
                src={Banana}
                alt="No way!"
                height={80}
                fit='scale-down'
                className='my-8 p-2 '
              />
            </Card.Section>
            <h3 className='mb-4 mt-4 font-semibold text-xl text-center'>Post a bid</h3>
              <p className='mb-4 text-gray-500 text-md font-light text-center'>Do you have a bid you would like to upload to Bid Banana? Would you like help building a bid? Click here to get started!</p>        
              
              {/* Form logic */}
              <Modal
                centered
                size='lg'
                opened={opened1}
                onClose={() => setOpened1(false)}
                onSubmit={() => setOpened1(false)}
              >
                  {/* Upload Bid Form */}
                  <UploadBidForm/>
              </Modal>
            <div className='flex justify-center mx-2'>
            <Button
              variant='default'
              size='md'
              radius='xl'
              className="flex w-fit  hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border-black duration-900 text-gray-600 font-normal"
              onClick={() => uploadBidOnClick()}
            >
              Post a bid
            </Button>
            </div>
          </Card>

           {/* Card 2: Upload a bid source */}
           <Card
            shadow="sm"
            p="xl"
            component="a"
            withBorder
            radius='lg'
            className='hover:drop-shadow-lg transition-all ease-in-out duration-300'
            // href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
          
            >
            <Card.Section>
              <Image
                src={Bunch}
                alt="No way!"
                height={120}
                fit='scale-down'
                className='m-2 p-2'
              />
            </Card.Section>
            <h3 className='mb-4 mt-4 font-semibold text-xl text-center'>Request a bid source</h3>
              <p className='mb-4 text-gray-500 text-md font-light text-center'>Do you know of a bid or collection of bids, but don't see them on Bid Banana? You can add them by uploading a bid source here!</p>
          
              {/* Form logic */}
              <Modal
                centered
                size='lg'
                opened={opened2}
                onClose={() => setOpened2(false)}
                onSubmit={() => setOpened2(false)}

              >
                  {/* Upload Bid Form */}
                  <UploadBidSourceForm/>
              </Modal>
            <div className='flex justify-center mx-2'>
            <Button
              variant='default'
              size='md'
              radius='xl'
              className="flex w-fit  hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border-black duration-900 text-gray-600 font-normal"
              onClick={() => uploadBidSourceOnClick()}
            >
              Request a bid source
            </Button>
            </div>
          </Card>
    </SimpleGrid>    
    </section>
    </div>
  )
}

export default Upload