import { useState } from "react";
import {
  createStyles,
  Header,
  Divider,
  Group,
  Modal,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  Center,
} from "@mantine/core";
import { ContactHelp, User, UserMobile } from ".";
import logo from "../data/BBidBananaLogoFullColor.svg";
import { GiBananaBunch } from "react-icons/gi";
import { useDisclosure } from "@mantine/hooks";
import { Link, NavLink } from "react-router-dom";
import { useUserAuth } from "../contexts/UserAuthContext";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.md,

    [theme.fn.smallerThan("sm")]: {
      height: 42,
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  mobileNav: {},

  hiddenMobile: {
    [theme.fn.smallerThan("lg")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("lg")]: {
      display: "none",
    },
  },
}));

export function HeaderMegaMenu() {
  const [modalOpened, setModalOpened] = useState(false);
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes } = useStyles();

  const AuthContext = useUserAuth();

  const user = AuthContext.user;

  // Function to handle Get Help popup and close the drawer if it's open
  const toggleModal = () => {
    setModalOpened(!modalOpened);
    if (drawerOpened) {
      closeDrawer();
    }
  };

  return (
    <Box pb={0}>
      <Header
        className="bg-leaf-lightest-green  w-full  border-leaf-lightest-green nowrap"
        height={120}
        px="sm"
      >
        <div id="wrapper" className="pb-2">
          <Group
            position="apart"
            sx={{ height: "100%" }}
            className="flex-nowrap w-full"
          >
            <Link to="/" className=" ml-2.5 pb-3">
              <img src={logo} size="sm" alt="Bid Banana" className="pb-0" />
              {/* <BananaLogo /> */}
            </Link>
            <Group className={classes.hiddenMobile}>
              <div className="px-4 capitalize">
                <NavLink
                  to="/search"
                  className={({ isActive }) =>
                    isActive
                      ? " text-md inline-block font-medium p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:h-1 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow before:w-full before:opacity-100"
                      : " text-md inline-block p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:w-0 before:h-1 before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow hover:before:w-full hover:before:opacity-100"
                  }
                >
                  Search
                </NavLink>
              </div>
              {/* <div className="px-4 capitalize">
                <NavLink
                  to="/private-bids"
                  className={({ isActive }) =>
                    isActive
                      ? " text-md inline-block font-medium p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:h-1 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow before:w-full before:opacity-100"
                      : " text-md inline-block p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:w-0 before:h-1 before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow hover:before:w-full hover:before:opacity-100"
                  }
                >
                  Company Profile
                </NavLink>
              </div> */}
              <div className="px-4 capitalize">
                <NavLink
                  to="/favorites"
                  className={({ isActive }) =>
                    isActive
                      ? " text-md inline-block font-medium p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:h-1 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow before:w-full before:opacity-100"
                      : " text-md inline-block p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:w-0 before:h-1 before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow hover:before:w-full hover:before:opacity-100"
                  }
                >
                  Favorites
                </NavLink>
              </div>
              <div className="px-4 capitalize">
                <NavLink
                  to="/getexperthelp"
                  className={({ isActive }) =>
                    isActive
                      ? " text-md inline-block font-medium p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:h-1 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow before:w-full before:opacity-100"
                      : " text-md inline-block p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:w-0 before:h-1 before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow hover:before:w-full hover:before:opacity-100 "
                  }
                >
                  Get Expert Help
                </NavLink>
              </div>
              <div className="px-4 capitalize">
                <NavLink
                  to="/blog"
                  className={({ isActive }) =>
                    isActive
                      ? " text-md inline-block font-medium p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:h-1 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow before:w-full before:opacity-100"
                      : " text-md inline-block p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:w-0 before:h-1 before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow hover:before:w-full hover:before:opacity-100 "
                  }
                >
                  Blog
                </NavLink>
              </div>
              <div className="px-4 capitalize">
                <NavLink
                  to="/upload"
                  className={({ isActive }) =>
                    isActive
                      ? " text-md inline-block font-medium p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:h-1 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow before:w-full before:opacity-100"
                      : " text-md inline-block p-1 relative cursor-pointer transition-all duration-600 before:absolute before:-bottom-2 before:left-0 before:w-0 before:h-1 before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-leaf-medium-green via-leaf-lightest-green before:to-bb-yellow hover:before:w-full hover:before:opacity-100"
                  }
                >
                  Upload
                </NavLink>
              </div>
              <div className="px-4 pt-2">
                <User />
              </div>
            </Group>
            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              className={classes.hiddenDesktop}
            />
          </Group>
        </div>
      </Header>
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title=""
        className={classes.hiddenDesktop}
        zIndex={1000000}
        icon={<GiBananaBunch />}
      >
        <ScrollArea sx={{ height: "calc(100vh - 60px)" }} mx="-md">
          <h1 className="pl-3 font-semibold text-lg">Explore</h1>
          <Divider my="sm" />
          <Link to="/search" onClick={closeDrawer} className={classes.link}>
            Search
          </Link>
          <Link to="/favorites" onClick={closeDrawer} className={classes.link}>
            Favorites
          </Link>
          <Link to="/private-bids/register" onClick={closeDrawer} className={classes.link}>
            Private Bids
          </Link>
          <Link
            to="/getexperthelp"
            onClick={closeDrawer}
            className={classes.link}
          >
            Get expert help
          </Link>
          <Link to="/blog" onClick={closeDrawer} className={classes.link}>
            Blog
          </Link>
          <Link to="/upload" onClick={closeDrawer} className={classes.link}>
            Upload
          </Link>
          <Link to="/bidfinder" onClick={closeDrawer} className={classes.link}>
            Bid Finder
          </Link>
          <Link onClick={toggleModal} className={classes.link}>
            Contact Help
          </Link>
          <Link to="/settings" onClick={closeDrawer} className={classes.link}>
            Settings
          </Link>
          {user && user.admin && (
            <Link to="/admin" onClick={closeDrawer} className={classes.link}>
              Admin Dashboard
            </Link>
          )}
          <Divider my="sm" />
          <Group position="center" grow pb="xl" px="md">
            <UserMobile close={closeDrawer} />
          </Group>
        </ScrollArea>
      </Drawer>
      <Modal
        centered
        fullScreen
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        onSubmit={() => setModalOpened(false)}
      >
        {/* Contact Help Form */}
        <ContactHelp />
      </Modal>
    </Box>
  );
}
