import React, { useState } from "react";
import { useForm } from "@mantine/form";
import { Button, TextInput, Image } from "@mantine/core";
import Bidlabgroup from "../../data/Bidlabgroup.svg";
import { setDoc, collection, doc } from "firebase/firestore";
import { db } from "../../Firebase";
import { showNotification, updateNotification } from "@mantine/notifications";
import { AiOutlineCheck } from "react-icons/ai";
import { DatePickerInput } from "@mantine/dates";

import { FloatLabel } from "primereact/floatlabel";
import { IconX } from "@tabler/icons";

const BidFinderClientEnrollmentForm = ({ closeModal }) => {
  const [value, setValue] = useState("");
  const [date, setDate] = useState(null);

  const dataForm = useForm({
    initialValues: {
      organizationName: "",
      associatedEmails: "",
    },
  });

  return (
    <div className="m-x-xs m-3">
      <form
        onSubmit={dataForm.onSubmit(async (data, values) => {
          try {
            if (data.organizationName != "" && date != null && data.associatedEmails != "") {
              showNotification({
                id: "add-bf-client",
                title: "Adding Bid Finder client...",
                color: "bb-light-green.0",
                loading: true,
              });

              const clientDocRef = doc(collection(db, "bidfinder_clients"));

              try {
                // Set the user's details in the Document
                await setDoc(clientDocRef, {
                  organization_name: data.organizationName,
                  emails: data.associatedEmails,
                  email_notification_day: "Monday", // Set the initial value for to monday
				  end_date: date.toLocaleDateString(),
                  send_emails: true,
                });

                updateNotification({
                  id: "add-bf-client",
                  title: "Success!",
                  color: "green",
                  icon: <AiOutlineCheck size={16} />,
                  autoClose: 2000,
                });
              } catch (e) {
                console.log(e);
                updateNotification({
                  id: "add-bf-client",
                  title: "Failed to add Bid Finder client",
                  color: "red",
                  icon: <IconX size={16} />,
                  autoClose: 2000,
                });
              }
            } else {
              alert("field cannot be left empty");
            }
          } catch (e) {
            console.log(e);
          }
          closeModal();
        })}
      >
        <section className="m-2 p-2 flex justify-center  border-1 rounded-xl bg-bb-yellow">
          <Image src={Bidlabgroup} position="centered" fit="contain" width={200} height={200} />
        </section>
        <section className="m-2 p-2  border-1 rounded-xl">
          <article className="px-2">
            <h1 className="font-medium">Add a Bid Finder client</h1>
            {/* <h2 className="text-justify">
							Interested in uploading a bid to Bid Banana or learning more about how to build one? Please confirm the following fields
							and a member of our team will contact you shortly. Thanks for your interest and we look forward to working with you.
						</h2> */}
          </article>
        </section>
        <section className="m-2 p-2 border-1 rounded-xl">
          <article className="px-2">
            <TextInput label="Organization Name" placeholder="" required {...dataForm.getInputProps("organizationName")} />
            <DatePickerInput mt="md" label="Service End Date" required placeholder="" value={date} onChange={setDate} />
            <TextInput mt="md" label="Associated Emails" required placeholder="" {...dataForm.getInputProps("associatedEmails")} />
            <p className="text-sm text-gray-500">List multiple emails in a comma separated list, eg. "example@gmail.com, example1@gmail.com"</p>
            {/* <TextInput
							mt="md"
							mb="md"
							required
							label="Company/Organization Name"
							placeholder="Company/Organization Name"
							{...dataForm.getInputProps("organizationName")}
						/>
						<TextInput
							mt="md"
							mb="md"
							required
							label="Phone number"
							placeholder="Phone number"
							{...dataForm.getInputProps("phoneNumber")}
						/> */}
          </article>
        </section>

        <section className="m-2 p-1 flex justify-start mt-2">
          <Button
            variant="outline"
            size="sm"
            radius="xl"
            type="submit"
            className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
          >
            Add Client
          </Button>
        </section>
      </form>
    </div>
  );
};

export default BidFinderClientEnrollmentForm;
