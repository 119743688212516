import {
  createStyles,
  Image,
  Container,
  Title,
  Text,
  Button,
  SimpleGrid,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import BananaMan from "../data/BananaMan.svg";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const useStyles = createStyles((theme) => ({
  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

const BidNotFound = () => {
  useAbleCDPTracking()

  const { classes } = useStyles();

  const navigate = useNavigate();

  const goSearch = () => {

    navigate("/search");
  };

  const goBlog = () => {

    navigate("/blog");
  };

  return (
    <div
      id="wrapper"
      className="h-fit flex-grow w-full relative justify-center"
    >
      <Container className="mt-20">
        <SimpleGrid
          spacing={80}
          cols={2}
          breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}
        >
          <Image src={BananaMan} className={classes.mobileImage} />
          <div>
            <Title className="font-semibold font-Barlow mb-4 sm:mt-12 ">
              This bid does not exist...
            </Title>
            <Text color="dimmed" size="lg">
              The bid you are trying to view does not exist. You may have
              mistyped the address, or the bid has expired and has been removed.
            </Text>
            <div className="flex justify-center sm:block">
              <Button
                onClick={goSearch}
                variant="outline"
                size="lg"
                type="submit"
                radius="xl"
                mt="xl"
                className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-6 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              >
                Back to search
              </Button>
              <Button
                onClick={goBlog}
                variant="outline"
                size="lg"
                type="submit"
                radius="xl"
                mt="xl"
                className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-yellow bg-bb-yellow border-1 border-black hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              >
                Visit blog
              </Button>
            </div>
          </div>
          <Image src={BananaMan} className={classes.desktopImage} />
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default BidNotFound;
