import React, { useEffect, useState } from "react";
import { getFirestore } from "firebase/firestore";
import { getDocs, collection, doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import KickoffBidFinder from "../components/Forms/KickoffBidFinder";
import AiIcon from "../components/AiIcon";
import { BananaBanner } from "../components";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";
import { Button, Modal, Tooltip, Spoiler, Accordion, Anchor, Select, Text, Card } from "@mantine/core";
import { HiOutlineLockClosed } from "react-icons/hi";
import { TiCreditCard } from "react-icons/ti";
import { TbCalendarTime } from "react-icons/tb";
import BidFinderSkeleton from "../components/Skeletons/BidFinderSkeleton";
import StateJSON from "../data/states.json";

const BidFinderClientPage = () => {
  // AbleCDP tracking hook
  useAbleCDPTracking();

  // State that contains the array of bids for this client
  const [bids, setBids] = useState([]);
  // A state that represents the index of the currently selected bid
  const [selectedBidIndex, setSelectedBidIndex] = useState(0);
  // A state used for opening and closing of popup form (<Modal/>)
  const [modalOpened, setModalOpened] = useState(false);
  // Loading state
  const [loading, setLoading] = useState(true);
  // Modal visibility state
  const [modalOpen, setModalOpen] = useState(false);
  // Modal data to be passed as props to the modal
  const [modalData, setModalData] = useState(null);
  // Sort by state to determine the order of the bids ("Recently Added", or "Closing Soon")
  const [sortBy, setSortBy] = useState("Recently Added");

  // Client detail data
  const [clientData, setClientData] = useState(null);
  console.log(bids);

  // Set close_cutoff date for conditionally displaying the "Closed" tags
  const closed_cutoff = new Date();
  closed_cutoff.setDate(closed_cutoff.getDate() - 1);

  // Fetch clientId from the URL
  const { clientId } = useParams();

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
  };

  // Logic to switch the sorting mode
  useEffect(() => {
    let sortedBids = [...bids];
    if (sortBy === "Recently Added") {
      sortedBids = sortedBids.sort((a, b) => b.addedTimestamp - a.addedTimestamp);
    } else if (sortBy === "Closing Soon") {
      sortedBids = sortedBids.sort((a, b) => a.close_timestamp - b.close_timestamp);
    } else if (sortBy === "Title (ascending)") {
      sortedBids = sortedBids.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
    } else if (sortBy === "Title (descending)") {
      sortedBids = sortedBids.sort((a, b) => b.title.toLowerCase().localeCompare(a.title.toLowerCase()));
    }

    setBids(sortedBids);
  }, [sortBy]);

  // Logic for fetching the current bids for this client
  useEffect(() => {
    if (clientId) {
      const db = getFirestore();
      setLoading(true); // Start loading

      const clientBidsCollection = collection(db, "bidfinder_clients", clientId, "saved_bids");
      const clientDoc = doc(db, "bidfinder_clients", clientId);

      getDoc(clientDoc)
        .then((docSnap) => {
          const clientData = docSnap.data();
          setClientData(clientData);
        })
        .then(() => {
          // Fetch current Bid Finder client's saved bids
          getDocs(clientBidsCollection).then((bidsSnapshot) => {
            const fetchedBids = bidsSnapshot.docs.map((savedBidDoc) => ({
              id: savedBidDoc.id,
              ...savedBidDoc.data(),
            }));

            const fetchedBidsIDs = fetchedBids.map((bid) => bid.objectID);

            // Fetch the most recent bid data if available
            Promise.all(
              fetchedBidsIDs.map((id, index) => {
                return getDoc(doc(db, "bids", id)).then((bidDoc) => {
                  if (bidDoc.exists()) {
                    const bidData = bidDoc.data();
                    bidData.addedDate = fetchedBids[index].addedDate;
                    bidData.addedTimestamp = fetchedBids[index].addedTimestamp;
                    return bidData;
                  } else {
                    // else use the previously saved bid snapshot
                    return fetchedBids[index];
                  }
                });
              })
            ).then((fetchedFreshData) => {
              let sortedBids = fetchedFreshData;
              if (sortBy === "Recently Added") {
                sortedBids = fetchedFreshData.sort((a, b) => b.addedTimestamp - a.addedTimestamp);
              } else if (sortBy === "Closing Soon") {
                sortedBids = fetchedFreshData.sort((a, b) => a.close_timestamp - b.close_timestamp);
              }

              // Set states accordingly
              setBids(sortedBids);
              setLoading(false);
            });
          });
        })
        .catch((error) => {
          // Catch and log errors
          console.error("Error fetching data: ", error);
        });
    }
  }, [clientId]);

  // This componenet represents a single bid on the left side of the Bid Finder page
  const BidTab = (props) => {
    const isBidClosed = new Date(props.bid.close_date) < closed_cutoff;

    // Onclick callback function for activiating the kickoff bid modal
    const kickoffBidOnClick = () => {
      setModalData(bids[selectedBidIndex]);
      setModalOpened(true);
    };

    // Sets the selectedBidIndex state to the index of the clicked bid.
    const bidTabOnClick = (index) => {
      setSelectedBidIndex(index);
    };

    const bidDetailsOnClick = () => {
      window.open(`/bidfinder/bid/${props.bid.doc_id || props.bid.objectID}`, "_blank");
    };

    // If the currently selectedBidIndex matches the index of this tab, give it a blue border.
    let classes = "border-bb border-2 rounded relative min-w-3xl cursor-pointer hover:border-bb-dark-blue flex px-4 py-3";
    if (props.index === selectedBidIndex) {
      classes = "bg-bb-yellow border-2 border rounded relative min-w-3xl cursor-pointer border-bb-dark-blue flex px-4 py-3";
    }

    return (
      <div id="BidContent">
        <div
          id="CardShell"
          className={classes}
          onClick={function (e) {
            bidTabOnClick(props.index);
          }}
        >
          <div id="CardInside" className="min-w-full w-100 flex-col grow  border-fuchsia-500">
            <span className="flex ml-2 font-normal text-sm shrink-0 justify-between">
              <p className="w-4/5 sm:pr-4 sm:w-4/5 text-base font-light truncate text-gray-800">{props.title}</p>
            </span>
            <span className="hidden sm:flex ml-2 text-xs text-gray-800 font-bold">
              State:&nbsp;
              <p className="text-xs text-gray-600 font-light">{StateJSON[props.state]}</p>
            </span>
            <span className="hidden sm:flex ml-2 text-xs text-gray-800 font-bold">
              Close Date:
              <p className="pl-1 text-xs text-gray-800 font-light">{props.close_date.slice(-4) !== "2099" ? props.close_date : "TBD"}</p>
              {isBidClosed && <p className="pl-1 text-xs text-red-600 font-bold">** CLOSED **</p>}
            </span>
            {/* The collapsable content for mobile/small screen view*/}
            <Accordion variant="filled" className="bg-white sm:hidden" chevronPosition="right">
              <Accordion.Item value="close-date">
                <Accordion.Control className="items-end">
                  <span className="flex ml-2 text-xs font-bold">
                    State:&nbsp;
                    <p className="pl-1 text-xs text-gray-600 font-light">{StateJSON[props.state]}</p>
                  </span>
                  <span className="flex ml-2  text-xs text-gray-800 font-bold">
                    Close Date:&nbsp;
                    <p className="pl-1 text-xs text-gray-600 font-light">{props.close_date.slice(-4) !== "2099" ? props.close_date : "TBD"}</p>
                    {isBidClosed && <p className="pl-1 text-xs text-red-600 font-bold">** CLOSED **</p>}
                  </span>
                </Accordion.Control>
                <Accordion.Panel>
                  {/* Specific bid content here  */}
                  <div id="BidDetails" className="p-4 min-w-full flex-wrap ">
                    <div id="JobDescriptionContent">
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm flex-wrap">
                        Title:&nbsp;&nbsp;
                        <p className="pl-0 font-light text-gray-800 text-sm ">{props.bid.title}</p>
                      </span>
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm flex-wrap">
                        Agency:&nbsp;&nbsp;
                        <p className="pl-0 font-light text-gray-800 text-sm break-normal">{props.bid.agency}</p>
                      </span>
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                        State:
                        <p className="pl-2 font-light text-gray-800 text-sm ">{StateJSON[props.bid.state]}</p>
                      </span>
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                        Open Date:
                        <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.open_date}</p>
                      </span>
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                        Close Date:
                        <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.close_date.slice(-4) !== "2099" ? props.bid.close_date : "TBD"}</p>
                      </span>
                      <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                        Date Added to Bid Finder:
                        <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.addedDate}</p>
                      </span>
                      <span className="pl-2 ml-2 font-semibold text-gray-800 text-sm">
                        Description:
                        <Spoiler
                          maxHeight={40}
                          showLabel="Show more"
                          hideLabel="Hide"
                          className={`text-gray-800 text-sm flex-column overflow-auto ${
                            props.bid.description_use_newlines ? "whitespace-pre-line leading-none" : "whitespace-normal"
                          } mr-3 git p-2 ml-2 font-light justify-center`}
                        >
                          {props.bid.description}
                        </Spoiler>
                      </span>
                      <span className="inline-flex mx-3 pt-2 pl-2">
                        <div className={props.bid.ai_enhanced ? "visible" : "hidden"}>
                          <Tooltip
                            label="Some data for this bid has been extracted using AI"
                            withArrow
                            multiline
                            position="bottom"
                            width={240}
                            openDelay={400}
                            transition="pop"
                            transitionDuration={200}
                            color="bb-blue"
                          >
                            <p className="mr-4">
                              <AiIcon size={24} className="text-gray-400" />
                            </p>
                          </Tooltip>
                        </div>
                        <div className={props.bid.login_required ? "visible" : "hidden"}>
                          <Tooltip
                            label="This bid requires an additional login"
                            withArrow
                            multiline
                            position="bottom"
                            width={240}
                            openDelay={400}
                            transition="pop"
                            transitionDuration={200}
                            color="bb-blue"
                          >
                            <p className="mr-4">
                              <HiOutlineLockClosed size={24} className="text-gray-400" />
                            </p>
                          </Tooltip>
                        </div>
                        <div className={props.bid.payment_required ? "visible" : "hidden"}>
                          <Tooltip
                            label="This bid requires additional payment"
                            withArrow
                            multiline
                            position="bottom"
                            width={240}
                            openDelay={400}
                            transition="pop"
                            transitionDuration={200}
                            color="bb-blue"
                          >
                            <p className="mr-4">
                              <TiCreditCard size={26} stroke={1.5} className="text-gray-400" />
                            </p>
                          </Tooltip>
                        </div>
                        <div className={new Date(props.bid.open_date) > new Date() ? "visible" : "hidden"}>
                          <Tooltip label="This is a forecasted opportunity" withArrow multiline position="bottom" width={215} openDelay={400} transition="pop" transitionDuration={200} color="bb-blue">
                            <p className="">
                              <TbCalendarTime size={24} className="text-gray-400" />
                            </p>
                          </Tooltip>
                        </div>
                      </span>
                      {/* Link to bid and CTA button */}
                      <div className="flex justify-center mx-2">
                        <Button
                          variant="default"
                          size="sm"
                          onClick={() => bidDetailsOnClick()}
                          disabled={isBidClosed}
                          radius="xl"
                          className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 hover:bg-bb-yellow bg-bb-yellow hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 border:black duration-900 text-black font-normal border-black"
                        >
                          Details
                        </Button>
                        <Anchor href={props.bid.url} target="_blank" className="text-gray-600 text-sm font-normal hover:text-gray-600 no-underline hover:no-underline">
                          <Button
                            variant="default"
                            size="sm"
                            radius="xl"
                            className=" hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal"
                          >
                            Link to bid
                          </Button>
                        </Anchor>

                        <div className="flex justify-center mx-2">
                          {/* kickoff button */}
                          <Button
                            onClick={() => kickoffBidOnClick()}
                            variant="outline"
                            size="sm"
                            radius="xl"
                            className="hover:shadow-[0_5px_0px_rgba(0,0,0)] text-sm transition-all font-normal ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black "
                          >
                            Get started!
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    );
  };

  const BidCard = (props) => {
    const isBidClosed = new Date(props.bid.close_date) < closed_cutoff;

    const bidDetailsOnClick = () => {
      window.open(`/bidfinder/bid/${props.bid.doc_id || props.bid.objectID}`, "_blank");
    };

    // Open the kickoff modal
    const kickoffBidOnClick = () => {
      setModalData(bids[selectedBidIndex]);
      setModalOpened(true);
    };

    return (
      <>
        <div id="IndividualBidContent" className="hidden sm:flex relative basis-3/5 bg-white sm:border-1 overflow-auto">
          <div className="flex grow-0 shrink-0 basis-full h-full">
            {/* <div>later For mobile</div> */}
            <article id="scrollable active content area" className="min-w-full">
              <div>
                {/* !! when content is scrolled header areas stays fixed */}
                <div id="StickyHeader" className="min-w-full relative my-auto h-20">
                  <div className="w-full absolute pt-0">
                    {/* responsive to scroll*/}
                    <div className="border-b-1 z-0 max-w-3/5 min-w-full">
                      <div id="BidDataViewsHeader">
                        <div className="inline-flex flex-nowrap w-full flex-row justify-between p-1">
                          <div id="LeftSideOfStickyHeader" className="flex-col grow-1, shrink-1, basis-auto pt-1">
                            {/* Rows on left side of sticky header: Title, State, Due date */}
                            <div id="Title" className="h-auto flex-col justify-center py-3 text-base font-bold">
                              <span className="flex ml-2 pl-1 font-normal text-sm shrink-0 ">
                                <p className="pl-0 text-lg font-semibold text-gray-800">{props.bid.title}</p>
                                {isBidClosed && <p className="pl-3 text-lg text-red-600 font-bold mr-4">CLOSED</p>}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="DetailedDescrition" className="flex-1 relative break-all min-w-full ">
                  <div className="bg-white pb-16">
                    <div id="BidDetails" className="p-4 min-w-full flex-wrap ">
                      <div id="JobDescriptionContent">
                        {/* Title, Agency, State, Open Date, Close Date, Description */}
                        <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm flex-wrap">
                          Title:&nbsp;&nbsp;
                          <p className="pl-0 font-light text-gray-800 text-sm ">{props.bid.title}</p>
                        </span>
                        <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm flex-wrap">
                          Agency:&nbsp;&nbsp;
                          <p className="pl-0 font-light text-gray-800 text-sm break-normal">{props.bid.agency}</p>
                        </span>
                        <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                          State:
                          <p className="pl-2 font-light text-gray-800 text-sm ">{StateJSON[props.bid.state]}</p>
                        </span>
                        <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                          Open Date:
                          <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.open_date}</p>
                        </span>
                        <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                          Close Date:
                          <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.close_date.slice(-4) !== "2099" ? props.bid.close_date : "TBD"}</p>
                        </span>
                        <span className="flex p-2 ml-2 font-semibold text-gray-800 text-sm">
                          Date Added to Bid Finder:
                          <p className="pl-2 font-light text-gray-800 text-sm ">{props.bid.addedDate}</p>
                        </span>
                        <span className="pl-2 ml-2 font-semibold text-gray-800 text-sm">
                          Description:
                          <Spoiler
                            maxHeight={40}
                            showLabel="Show more"
                            hideLabel="Hide"
                            className={`text-gray-800 text-sm flex-column overflow-auto ${
                              props.bid.description_use_newlines ? "whitespace-pre-line leading-none" : "whitespace-normal"
                            } mr-3 git p-2 ml-2 font-light justify-center`}
                          >
                            {props.bid.description}
                          </Spoiler>
                        </span>
                        <span className="inline-flex mx-3 pt-2 pl-2">
                          <div className={props.bid.ai_enhanced ? "visible" : "hidden"}>
                            <Tooltip
                              label="Some data for this bid has been extracted using AI"
                              withArrow
                              multiline
                              position="bottom"
                              width={240}
                              openDelay={400}
                              transition="pop"
                              transitionDuration={200}
                              color="bb-blue"
                            >
                              <p className="mr-4">
                                <AiIcon size={24} className="text-gray-400" />
                              </p>
                            </Tooltip>
                          </div>
                          <div className={props.bid.login_required ? "visible" : "hidden"}>
                            <Tooltip
                              label="This bid requires an additional login"
                              withArrow
                              multiline
                              position="bottom"
                              width={240}
                              openDelay={400}
                              transition="pop"
                              transitionDuration={200}
                              color="bb-blue"
                            >
                              <p className="mr-4">
                                <HiOutlineLockClosed size={24} className="text-gray-400" />
                              </p>
                            </Tooltip>
                          </div>
                          <div className={props.bid.payment_required ? "visible" : "hidden"}>
                            <Tooltip
                              label="This bid requires additional payment"
                              withArrow
                              multiline
                              position="bottom"
                              width={240}
                              openDelay={400}
                              transition="pop"
                              transitionDuration={200}
                              color="bb-blue"
                            >
                              <p className="mr-4">
                                <TiCreditCard size={26} stroke={1.5} className="text-gray-400" />
                              </p>
                            </Tooltip>
                          </div>
                          <div className={new Date(props.bid.open_date) > new Date() ? "visible" : "hidden"}>
                            <Tooltip
                              label="This is a forecasted opportunity"
                              withArrow
                              multiline
                              position="bottom"
                              width={215}
                              openDelay={400}
                              transition="pop"
                              transitionDuration={200}
                              color="bb-blue"
                            >
                              <p className="">
                                <TbCalendarTime size={24} className="text-gray-400" />
                              </p>
                            </Tooltip>
                          </div>
                        </span>
                        <Modal centered size="lg" opened={modalOpened} onClose={() => setModalOpened(false)} onSubmit={() => setModalOpened(false)}>
                          <KickoffBidFinder url={modalData ? modalData.url : ""} title={modalData ? modalData.title : ""} closeModal={closeModal} />
                        </Modal>

                        {/* Link to bid and CTA button */}
                        <div className="flex justify-center mx-2">
                          <div className="flex mr-auto"></div>

                          <Anchor href={props.bid.url} target="_blank" className="text-gray-600 font-normal text-sm hover:text-gray-600 no-underline hover:no-underline">
                            <Button
                              variant="default"
                              size="md"
                              radius="xl"
                              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal"
                            >
                              Link to bid
                            </Button>
                          </Anchor>

                          <Button
                            variant="default"
                            size="md"
                            disabled={isBidClosed}
                            onClick={() => bidDetailsOnClick()}
                            radius="xl"
                            className="hover:shadow-[0_5px_0px_rgba(0,0,0)] ml-2 hover:bg-bb-yellow bg-bb-yellow hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 border:black duration-900 text-black font-normal border-black"
                          >
                            Details
                          </Button>

                          <div className="flex justify-center mx-2">
                            {/* kickoff button */}
                            <Button
                              onClick={() => kickoffBidOnClick()}
                              variant="outline"
                              size="md"
                              radius="xl"
                              className="hover:shadow-[0_5px_0px_rgba(0,0,0)]  transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black "
                            >
                              Get started!
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </>
    );
  };

  // Conditional rendering based on saved bid content and loading state
  let content;
  let bidTabs;
  let bidContent;
  if (loading) {
    content = <BidFinderSkeleton />;
  } else if (bids === null || bids.length === 0) {
    content = (
      <div id="main container" className="py-8 px-2 sm:float-right">
        <Card className="lg:py-0 pt-12">
          <Text className="flex text-xl justify-center">Bid Finder for Client ID '{clientId}' is empty.</Text>
          <div className="mt-2">
            <Text fz="sm" c="dimmed" align="center">
              More bids will appear once we add them to this Bid Finder.
            </Text>
          </div>
        </Card>
      </div>
    );
  } else {
    // This component displays all of the current bids as tabs.
    bidTabs = bids.map((bid, index) => <BidTab key={index} index={index} bid={bid} objectID={bid.id} title={bids[index].title} state={bids[index].state} close_date={bids[index].close_date} />);

    // This component displays the currently selected bid.
    bidContent = <BidCard bid={bids[selectedBidIndex]} objectID={bids[selectedBidIndex].id} title={bids[selectedBidIndex].title} />;

    // Main page content body
    content = (
      <div id="PageContent" className="h-full relative z-10 max-w-7xl w-full margin-auto">
        <div id="PageBodyContents">
          <Select
            className="w-fit ml-auto mb-5 mr-5"
            label="Sort By"
            defaultValue="Recently Added"
            onSearchChange={setSortBy}
            searchValue={sortBy}
            nothingFound="No options"
            data={["Recently Added", "Closing Soon", "Title (ascending)", "Title (descending)"]}
          />
          <div className="h-full">
            <div className=" flex sm:mb-12">
              <article id="MainColumn" className="w-full sm:flex-row mr-2 h-[34rem] border-1 bg-white border-t-1 sm:basis-2/5 sm:overflow-auto pb-12 transition-all ease-in-out duration-300">
                {bidTabs}
                <div id="FooterOrPagination" className="m-4 sm:hidden">
                  <br />
                  <br />
                </div>
              </article>
              {bidContent}
            </div>
          </div>
          {/* <div className="text-center">
            <p className="font-semibold mb-2">Tentative End Date for your Bid Finder Service: </p>
            {clientData.end_date || "TBD"}
          </div> */}
        </div>
      </div>
    );
  }

  return (
    <>
      <div id="Bids" className="h-fit flex-grow flex-col w-full relative justify-center mb-40">
        <BananaBanner title1="Bid Finder" title2="ripe for the picking!" />
        <div id="BidsPageWrapper" className="h-fit flex flex-grow m-auto w-full relative justify-center">
          {content}
        </div>
      </div>
    </>
  );
};

export default BidFinderClientPage;
