import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ReactGA from "react-ga4";
// Helmet
import { HelmetProvider } from "react-helmet-async";
// Mantine
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import Chatbot from "./components/Chatbot";
import { ModalsProvider } from "@mantine/modals";
import "primereact/resources/themes/saga-green/theme.css";
import { PrimeReactProvider } from "primereact/api";

// Google analytics
ReactGA.initialize("G-CD48487V2T");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <PrimeReactProvider>
        <Chatbot />
        <MantineProvider
          theme={{
            fontFamily: "Barlow",
            colors: {
              "bb-blue": ["#d5eeed", "#5FCCDB", "#44CADC", "#2AC9DE", "#1AC2D9", "#11B7CD", "#09ADC3", "#0E99AC", "#128797", "#147885"],
              "bb-light-green": ["#F1F8F3", "#DAEFE1", "#C2E9D0", "#A6E9BE", "#87EEAB", "#7CDE9F", "#74CE94", "#6EBE8A", "#68AF81", "#63A178"],
              "bb-dark-green": ["#7D8C7F", "#778B7A", "#708974", "#6A886F", "#638869", "#5C8963", "#548A5C", "#57815D", "#58795D", "#59725C"],
              "bb-dark-yellow": ["#F6F4EF", "#F2EEE5", "#EFE9DA", "#EEE5CD", "#F0E3BF", "#F4E1AE", "#FDE299", "#F2D998", "#E6D198", "#DCC997"],
            },
            primaryShade: 6,
          }}
          withGlobalStyles
          withNormalizeCSS
        >
          <ModalsProvider>
            <Notifications position="bottom-center" limit={3} zIndex={1100}/>
            <App />
          </ModalsProvider>
        </MantineProvider>
      </PrimeReactProvider>
    </HelmetProvider>
  </React.StrictMode>
);
