import React from "react";
import { Modal, Button, Group, Text } from "@mantine/core";

const BidFinderDeleteConfirmationModal = ({ isOpen, onClose, onDelete, entityType }) => {
  // Decide the title and message based on entity Type
  const title = entityType === 'user' 
  ? 'Delete User' 
  : 'Delete Bid';

  const message = entityType === 'user' 
      ? 'Are you sure you want to delete this user?' 
      : 'Are you sure you want to delete this bid?';


  return (
    <Modal
      radius="lg"
      title={title}
      opened={isOpen}
      onClose={onClose}
      size="sm"
      centered
      shadow="md"
    >
      <div className="pb-4">
        <Text size="md">{message}</Text>
      </div>
      <Group position="right">
        <Button
          variant="default"
          size="sm"
          radius="xl"
          className=" hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="default"
          size="sm"
          radius="xl"
          className="flex w-fit justify-center hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow bg-bb-yellow border-black duration-900 text-gray-900 font-normal"
          onClick={onDelete}
        >
          DELETE
        </Button>
      </Group>
    </Modal>
  );
};

export default BidFinderDeleteConfirmationModal;