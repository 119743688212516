import { useState } from "react";
import { useUserAuth } from "../contexts/UserAuthContext.js";
import { Link } from "react-router-dom";
import { Menu, Button, Modal, Drawer } from "@mantine/core";
import { MdOutlineLogin, MdOutlineLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaUser, FaUserMinus, FaRegIdBadge } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { BiHelpCircle } from "react-icons/bi";
import { IoHeadsetOutline } from "react-icons/io5";
import ContactHelp from "./Forms/ContactHelp.js";
import { AiOutlineFileSearch } from "react-icons/ai";

const User = () => {
  // State for menu
  const [menuOpened, setMenuOpened] = useState(false);
  // State for Get Modal
  const [modalOpened, setModalOpened] = useState(false);
  const [error, setError] = useState();
  const { user, logOut } = useUserAuth();

  let navigate = useNavigate();

  // Function to handle opening and closing the menu
  const toggleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  // Function to handle Get Help popup
  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  // Function for handling logout process
  function handleLogout() {
    setError("");
    try {
      logOut();
    } catch {
      setError("Failed to log out");
      console.log(error);
    }
  }

  // Navigates to the login page
  const routeToLogin = () => {
    navigate("/login");
  };

  // Handle user account actions with dynamic components
  let userAction;
  let userImg;

  if (user) {
    if (user.admin) {
      userAction = (
        <>
          <Link to="/settings">
            <Menu.Item icon={<IoSettingsOutline size={16} />}>
              Account Settings
            </Menu.Item>
          </Link>
          <Link to="/bidfinder">
            <Menu.Item icon={<AiOutlineFileSearch size={18} />}>
              Bid Finder
            </Menu.Item>
          </Link>
          <Menu.Item icon={<BiHelpCircle size={16} />} onClick={toggleModal}>
            Contact Help
          </Menu.Item>
          <Link to="/admin">
            <Menu.Item icon={<FaRegIdBadge size={16} />}>
              Admin Dashboard
            </Menu.Item>
          </Link>
          <Menu.Item
            icon={<MdOutlineLogout size={16} />}
            onClick={handleLogout}
          >
            Sign out
          </Menu.Item>
        </>
      );
    } else {
      userAction = (
        <>
          <Link to="/settings">
            <Menu.Item icon={<IoSettingsOutline size={16} />}>
              Account Settings
            </Menu.Item >
          </Link>
          <Link to="/bidfinder">
            <Menu.Item icon={<AiOutlineFileSearch size={18} />}>
              Bid Finder
            </Menu.Item>
          </Link>
          <Menu.Item icon={<BiHelpCircle size={16} />} onClick={toggleModal}>
            Contact Help
          </Menu.Item>
          <Menu.Item
            icon={<MdOutlineLogout size={16} />}
            onClick={handleLogout}
          >
            Sign out
          </Menu.Item>
        </>
      );
    }

    if (user.photoURL != null) {
      userImg = (
        <img
          className="h-12 rounded-full cursor-pointer"
          src={user.photoURL}
          alt="User Account"
          referrerPolicy="no-referrer"
        />
      );
    } else {
      userImg = <FaUser size={22} />;
    }
  } else {
    // If user is not logged in, provide a Log In option.
    userAction = (
      <Menu.Item icon={<MdOutlineLogin size={14} />} onClick={routeToLogin}>
        Log In
      </Menu.Item>
    );
    userImg = <FaUser size={20} className="" />;
  }

  return (
    <>
      {user ? (
        <Menu
          width={200}
          shadow="md"
          transition="scale-y"
          withArrow={false}
          withinPortal
          offset={15}
          opened={menuOpened}
          onClose={() => setMenuOpened(false)}
        >
          <Menu.Target>
            <Button
              leftIcon={userImg}
              className="bg-leaf-lightest-green text-gray-700 rounded-full hover:bg-leaf-lightest-green transition duration-200 hover:scale-110"
              onClick={toggleMenu}
            ></Button>
          </Menu.Target>
          <Menu.Dropdown>{userAction}</Menu.Dropdown>
        </Menu>
      ) : (
        <div id="button container" className="pb-2">
          <Button
            onClick={routeToLogin}
            variant="default"
            size="md"
            radius="xl"
            className="flex w-fit justify-center hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow bg-bb-yellow border-black duration-900 text-gray-900 font-normal"
          >
            Log In
          </Button>
        </div>
      )}
      {/* Form logic */}
      <Modal
        centered
        fullScreen
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        onSubmit={() => setModalOpened(false)}
      >
        {/* Upload Bid Form */}
        <ContactHelp />
      </Modal>
    </>
  );
};

export default User;
