import React from "react";
import { Button, List, Anchor, ThemeIcon, Text } from "@mantine/core";
import Bidlabgroup from "../data/Bidlabgroup.svg";
import { IconCircleCheck } from "@tabler/icons";


// Sales component for Favorites and Bid Finder
const BidLabSalesComponent = () => {
  return (
    <div id="wrapper" className="h-fit m-auto flex-grow flex-col w-full relative justify-center ">
      <section className="bg-white">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center px-2 lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl">The Bid Lab’s Experts Are Ready To Help You Win RFPs</h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
              The Bid Lab is the only RFP consulting firm of its kind, specializing in guiding small and medium-sized businesses through the proposal response process. We have RFP experts ready to
              help you respond to that perfect opportunity you discover in Bid Banana.
            </p>
            <List
              type="unordered"
              size="lg"
              spacing="sm"
              className="text-gray-500 pb-5 "
              icon={
                <ThemeIcon color="#5184B2" size={24} radius="xl">
                  <IconCircleCheck size={16} />
                </ThemeIcon>
              }
            >
              <List.Item>Trusted by 100s of small business owners</List.Item>
              <List.Item>Experience working on 1000s of RFP responses</List.Item>
              <List.Item>
                <Text>
                  Success stories in virtually every industry you can check out by
                  <Anchor href="https://www.thebidlab.com/case-studies/" target="_blank" className="font-semibold text-green-300">
                    &nbsp;clicking here.
                  </Anchor>
                </Text>
              </List.Item>
            </List>
            <div className="flex justify-center sm:block my-4">
              <Button
                variant="outline"
                as="a"
                size="md"
                radius="xl"
                target="_blank"
                className="flex w-fit bg-bb-yellow hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-yellow border-black duration-900 text-gray-600 font-normal"
              >
                <Anchor href="https://www.thebidlab.com/contact/" target="_blank" className="text-gray-600 font-normal hover:text-gray-600 no-underline hover:no-underline">
                  Book a consultation
                </Anchor>
              </Button>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={Bidlabgroup} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BidLabSalesComponent;
