import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HeaderMegaMenu, FooterCentered, GlobalLoader, ReactHotJar, Articles, CompanyProfileConditionalNavigator } from "./components";
import {
	Search,
	Settings,
	Login,
	Getexperthelp,
	Blog,
	Home,
	Home2,
	Billing,
	Results,
	BidFinderAdminPage,
	BidFinderClientPage,
	Termsofuse,
	Favorites,
	Register,
	FAQ,
	NotFound,
	Upload,
	FinalQuestions,
	SavedSearches,
	ForgotPassword,
	CanceledPayment,
	AdminDashboard,
	AdminHiddenDashboard,
	Statuses,
	Payments,
	BidFinderSalesPage,
	MailStatuses,
	CompanyProfileRegistration,
	CompanyProfileHome,
	BidPage,
	BidFinderBidPage
} from "./pages";
import "./App.css";
import "@fontsource/roboto"; // Defaults to weight 400.
import "./index.css";
import { UserAuthContextProvider } from "./contexts/UserAuthContext";
import Privacypolicy from "./pages/Privacypolicy";
import CookiePolicy from "./pages/CookiePolicy";

function App() {
	return (
		<div className="h-full flex-col basis-1/2 sm:flex sm:flex-col sm:h-screen sm:relative ">
			<BrowserRouter>
				<UserAuthContextProvider>
					<ReactHotJar />
					<HeaderMegaMenu />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/home2" element={<Home2 />} />
						<Route path="/search" element={<Search />} />
						<Route path="/results" element={<Results />} />
						<Route path="/articles/:urlSlug" element={<Articles />} />
						<Route path="/bidfinder/:clientId" element={<BidFinderClientPage/>}/>
						<Route path="/blog" element={<Blog />} />
						<Route path="/billing" element={<Billing />} />
						<Route path="/favorites" element={<Favorites />} />
						<Route path="/upload" element={<Upload />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="/savedsearches" element={<SavedSearches />} />
						<Route path="/getexperthelp" element={<Getexperthelp />} />
						<Route path="/login" element={<Login />} />
						<Route path="/forgotPassword" element={<ForgotPassword />} />
						<Route path="/register" element={<Register />} />
						<Route path="/finalquestions" element={<FinalQuestions />} />
						<Route path="/privacypolicy" element={<Privacypolicy />} />
						<Route path="/faq" element={<FAQ />} />
						<Route path="/cookiepolicy" element={<CookiePolicy />} />
						<Route path="/termsofuse" element={<Termsofuse />} />
						<Route path="/loader" element={<GlobalLoader />} />
						<Route path="/canceledpayment" element={<CanceledPayment />} />
						<Route path="/admin" element={<AdminDashboard />} />
						<Route path="/admin/hidden" element={<AdminHiddenDashboard />} />
						<Route path="/admin/statuses/scrapers" element={<Statuses />} />
						<Route path="/admin/statuses/mail" element={<MailStatuses />} />
						<Route path="/admin/bidfinder" element={<BidFinderAdminPage />} />
						<Route path="/bidfinder" element={<BidFinderSalesPage />} />
						<Route path="/payments" element={<Payments />} />
						{/* <Route path="/private-bids/register" element={<CompanyProfileRegistration />} />
						<Route path="/private-bids" element={<CompanyProfileConditionalNavigator />} />
						<Route path="/private-bids/home" element={<CompanyProfileHome />} /> */}
						<Route path="/bid/:bidId" element={<BidPage />} />
						<Route path="/bidfinder/bid/:bidId" element={<BidFinderBidPage />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
					<FooterCentered />
				</UserAuthContextProvider>
			</BrowserRouter>
		</div>
	);
}

export default App;
