import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Button, Card, Center, Grid, Image, Text } from "@mantine/core";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import ReadingProgressBar from "./ReadingProgressBar";
import { Helmet } from "react-helmet-async";
import ArticleSkeleton from "./Skeletons/ArticleSkeleton";
import { delay } from "lodash";
import { IoChevronBackSharp } from "react-icons/io5";
import { httpsCallable, getFunctions } from "firebase/functions";
import { createClient } from "contentful";

const Articles = () => {
  const [singleBlogPost, setSingleBlogPost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const { urlSlug } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
    });

    client.getEntries({
      content_type: "blogPage", 
      'fields.urlSlug': urlSlug
    })
    .then((response) => {
      if (response.items.length > 0) {
        setSingleBlogPost(response.items[0]); 
        // console.log("Fetched article data:", response.items[0]); // Log the structure here
        if (window.uipe) {
          const pagePath = `/articles/${urlSlug}`;
          window.uipe('track', 'PageView', { path: pagePath });        }
      } else {
        console.log("No entry found for this slug");
      }
      setIsLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching single entry:", error);
      setIsLoading(false);
    });
  }, [urlSlug]);

  // Contentful options
  const options = {
    renderNode: {
      "embedded-asset-block": (node) => {
        return <img src={node.data.target.fields.file.url} />;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className="mb-4">{children}</p>;
      },
    
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h1 className=" my-2 font-bold text-2xl ">{children}</h1>;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        // Matching H2 style with H1
        return <h2 className="my-2 font-bold text-2xl">{children}</h2>;
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return <ol className="ml-10 list-decimal">{children}</ol>;
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li className="">{children}</li>;
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className="list-disc ml-10">{children}</ul>;
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={node.data.uri}
            className="text-cyan-700 inline-block underline avoid-break"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = node.data.target.fields;
        return (
          <img
            src={file.url}
            alt={title || ""}
            style={{ maxWidth: '100%' }}
          />
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const contentType = node.data.target.sys.contentType.sys.id;
        const contentPattern = /^imagesWithHyperlinks(\d+)?$/; // Updated regex to match both types
        
        if (contentPattern.test(contentType)) {
          // This block now matches both 'imagesWithHyperlinks' and 'imagesWithHyperlinksX'
          const { images, url } = node.data.target.fields;
          if (images && images.fields.file && url) {
            const imageUrl = images.fields.file.url;
            const imageTitle = images.fields.title || "Embedded Image";
            
            return (
              <a href={url} target="_blank" rel="noopener noreferrer">
                <img src={imageUrl} alt={imageTitle} style={{ maxWidth: '100%' }} />
              </a>
            );
          }
        } else {
          // Fallback or debugging for unsupported content types
          return <div>Unsupported content type: {contentType}</div>;
        }
      }
    }
  };
  // console.log("fields", singleBlogPost?.fields);

  const robotsContent = singleBlogPost?.fields?.metaRobotsIndex
    ? "index"
    : "noindex";
  const followContent = singleBlogPost?.fields?.crawlerFollow
    ? "follow"
    : "unfollow";

  return (
    <main
      id="wrapper"
      className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
    >
      <div className="text-center sm:text-left">
        <nav className="m-4 ml-0 sm:ml-5 mr-8 inline-block">
          <Link
            to="/Blog"
            state={{currentPage: location.state?.currentPage ? location.state.currentPage : 1}}
            id="Link"
            className="flex items-center text-cyan-700 font-semibold group"
          >
            <div className="mr-1 transition-transform ease-in-out duration-300 group-hover:-translate-x-1">
              <IoChevronBackSharp className="self-center" />
            </div>
            <span className="group-hover:underline">Bid Banana Blog</span>
          </Link>
        </nav>
      </div>
      {isLoading ? (
        <ArticleSkeleton />
      ) : (
        <div>
          {/* SEO */}
          <Helmet prioritizeSeoTags>
            <title>{singleBlogPost?.fields?.title}</title>
            <meta
              name="description"
              content={singleBlogPost?.fields?.metaDescription}
            />
             <meta
              name="keywords"
              content={singleBlogPost?.fields?.keywords}
            />
            <meta
              name="robots"
              content={`${robotsContent}, ${followContent}`}
            />
            <meta
              property="og:image"
              content={singleBlogPost?.fields?.image?.fields?.file?.url}
            />
            
          </Helmet>
          <ReadingProgressBar className="bg-[#5184B2] rounded-r-lg" />

          <article className="flex justify-center">
            <div className="w-full sm:max-w-4xl">
              <header>
                <div id="post">
                  <div id="post-header">
                    <Center>
                      <Text
                        id="blog-title"
                        className="mb-1 mt-5 items-center  sm:font-bold text-2xl  sm:text-5xl font-medium"
                        fw={700}
                        component="a"
                        align="center"
                      >
                        {singleBlogPost?.fields?.title}
                      </Text>
                    </Center>
                    <br />
                    <div className="m-4 sm:m-2">
                      <Image
                        src={singleBlogPost?.fields?.image?.fields?.file?.url}
                        alt={singleBlogPost?.fields?.title}
                        title=""
                        height="auto"
                        width="100%"
                        radius="md"
                        fit="cover"
                        className="w-full rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </header>
              <br />
              <section>
                <Center>
                  <div className="px-4 sm:mx-7 w-full">
                    <div className="font-normal text-base sm:text-lg overflow-hidden break-words word-wrap: break-word;">
                      {documentToReactComponents(
                        singleBlogPost?.fields?.body,
                        options
                      )}
                    </div>
                  </div>
                </Center>
              </section>
            </div>
          </article>
          <footer id="footer">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </footer>
        </div>
      )}
    </main>
  );
};

export default Articles;
